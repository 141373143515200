// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-de-apply-tsx": () => import("./../../../src/pages/de/apply.tsx" /* webpackChunkName: "component---src-pages-de-apply-tsx" */),
  "component---src-pages-de-index-tsx": () => import("./../../../src/pages/de/index.tsx" /* webpackChunkName: "component---src-pages-de-index-tsx" */),
  "component---src-pages-en-apply-tsx": () => import("./../../../src/pages/en/apply.tsx" /* webpackChunkName: "component---src-pages-en-apply-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-fr-apply-tsx": () => import("./../../../src/pages/fr/apply.tsx" /* webpackChunkName: "component---src-pages-fr-apply-tsx" */),
  "component---src-pages-fr-index-tsx": () => import("./../../../src/pages/fr/index.tsx" /* webpackChunkName: "component---src-pages-fr-index-tsx" */),
  "component---src-pages-nl-apply-tsx": () => import("./../../../src/pages/nl/apply.tsx" /* webpackChunkName: "component---src-pages-nl-apply-tsx" */),
  "component---src-pages-nl-index-tsx": () => import("./../../../src/pages/nl/index.tsx" /* webpackChunkName: "component---src-pages-nl-index-tsx" */)
}

